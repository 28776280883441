import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = (props) => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="section container">
      <h1 className="title is-size-1-desktop is-size-2-touch has-text-weight-bold">NOT FOUND</h1>
      <p>You just hit a page that doesn&#39;t exist.</p>
      <div ClassName="columns">
            <div className="column is-4 is-offset-4">
              <Img fluid={props.data.cogsImage.childImageSharp.fluid}/>
            </div>
          </div>
    </section>
  </Layout>
)

export default NotFoundPage

export const pageQuery = graphql `
  query {
    cogsImage: file(relativePath: { eq: "cogs.png"}) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;